var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useMemo, useCallback, } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownMenu } from '@shared/ui/menus/DropdownMenu';
import RouterLink from '@shared/ui/navigation/RouterLink';
import { logout } from '@entities/auth';
import { getAbsolutePath } from '@src/app/routing/helpers';
import { GetAvailableLanguages } from '@app/locale';
import { GlobalSidebarElements, changeLanguage, changeTheme, openSidebar, toggleDevComponents, } from '@store/slices/application';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/chevron-small-right-light.svg';
import { ReactComponent as LanguageCommonIcon } from '@icons/wolfkit-light/globe-light.svg';
import { ReactComponent as ProfileIcon } from '@icons/wolfkit-light/user-light.svg';
import { ReactComponent as ThemeLightIcon } from '@icons/wolfkit-light/sun-light.svg';
import { ReactComponent as ThemeDarkIcon } from '@icons/wolfkit-light/moon-light.svg';
import { ReactComponent as SettingsIcon } from '@icons/wolfkit-light/gear-light.svg';
import { ReactComponent as ShortcutsIcon } from '@icons/wolfkit-light/flame-light.svg';
import { ReactComponent as SupportIcon } from '@icons/wolfkit-light/question-circle-light.svg';
import { ReactComponent as LogoutIcon } from '@icons/wolfkit-light/arrow-from-shape-right-light.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-light/alert-circle-light.svg';
import { ReactComponent as GBIcon } from '@icons/languages/gb.svg';
import { ReactComponent as RUIcon } from '@icons/languages/ru.svg';
import { ReactComponent as CrownIconComponent } from '@icons/wolfkit-light/crown-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import IconText from '@shared/ui/icons/IconText';
import { Body } from '@src/components/styled/Typography';
import { isProfileComplete as isProfileCompleteFn, editProfileInfo, openCreateTraderForm as openTraderFormAction, } from '@entities/profile';
import { useSwitchProfileMutation } from '@shared/api/profile';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import MenuProfileSwitcher from '../MenuProfileSwitcher';
import ProfileInfo from '../ProfileInfo';
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.m,
    '& .MuiTypography-root': {
        color: props.theme.palette.text.primary,
        fontWeight: 400,
    },
}));
const ActiveSettingsContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
}));
const OptionSettingsContainer = styled(ContainerRow)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px`,
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const CrownIcon = styled(CrownIconComponent)(props => ({
    path: {
        fill: props.theme.palette.warning.main,
    },
}));
const RouterLinkStyled = styled(RouterLink)(props => ({
    textDecoration: 'none',
    width: '100%',
    color: 'unset',
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px`,
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    padding: props.theme.spacing_sizes.m,
    gap: props.theme.spacing_sizes.s,
}));
const ItemWrapper = styled(ContainerColumn)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px`,
}));
const TopbarProfileMenuComponent = ({ isOpen, onClose, }, anchorEl) => {
    const { currentUserProfile } = useAppSelector((state) => state.profile);
    const { user } = useAppSelector((state) => state.user);
    const { language: currentLanguage, theme: currentTheme, areDevComponentsEnabled, } = useAppSelector(state => state.app);
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const [switchProfile] = useSwitchProfileMutation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isProfileComplete = isProfileCompleteFn(currentUserProfile || undefined);
    const languages = useMemo(() => GetAvailableLanguages(t), [t]);
    const navigateToProfile = () => {
        if (currentUserProfile) {
            navigate(routes.MY_PROFILE);
            onClose();
        }
    };
    const onSwitchProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        if (user === null || user === void 0 ? void 0 : user.unactiveProfileId) {
            yield switchProfile({ profileId: user.unactiveProfileId });
        }
    });
    const goToSettings = () => {
        navigate(routes.SETTINGS);
        onClose();
    };
    const goToSupport = () => {
        navigate(routes.SUPPORT);
        onClose();
    };
    const onLogoutClick = () => {
        dispatch(logout());
    };
    const onLanguageChange = useCallback((_, lang) => {
        dispatch(changeLanguage(lang));
    }, [dispatch]);
    const onThemeChange = (_, selectedTheme) => {
        dispatch(changeTheme(selectedTheme));
    };
    const onDevComponentsToggleClick = () => {
        dispatch(toggleDevComponents());
    };
    const onDevComponentsClick = () => {
        navigate(routes.DEV_COMPONENTS);
        onClose();
    };
    const goToProfileEdit = () => {
        navigateToProfile();
        dispatch(editProfileInfo());
    };
    const openCreateTraderForm = () => {
        onClose();
        dispatch(openTraderFormAction());
        dispatch(openSidebar(GlobalSidebarElements.CreateTraderProfile));
    };
    const ThemeIcon = useMemo(() => {
        let icon = ThemeLightIcon;
        if (currentTheme === 'light') {
            icon = ThemeLightIcon;
        }
        else if (currentTheme === 'dark') {
            icon = ThemeDarkIcon;
        }
        return icon;
    }, [currentTheme]);
    const LanguagesIcon = (language) => {
        // TODO: rename locale to match gb english, not us
        if (language === 'en') {
            return GBIcon;
        }
        if (language === 'ru') {
            return RUIcon;
        }
        return GBIcon; // TODO: make default icon
    };
    const languageOptions = useMemo(() => ({
        header: t('settings.theme', { ns: 'common' }),
        showBackButton: true,
        children: Object.keys(languages)
            .map(languageKey => ({
            itemKey: languageKey,
            children: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: LanguagesIcon(languageKey), keepOriginalColor: true })), text: languages[languageKey] }),
            selected: languageKey === currentLanguage,
            onClick: onLanguageChange,
        })),
    }), [languages, currentLanguage, onLanguageChange, t]);
    const menuItems = useMemo(() => {
        let options = [
            {
                itemKey: 'profile-info',
                children: (_jsx(RouterLinkStyled, { to: getAbsolutePath(routes.MY_PROFILE), tabIndex: -1, children: _jsx(ProfileInfo, { onClick: navigateToProfile }) })),
                addDivider: true,
                size: 'large',
            },
        ];
        if (user === null || user === void 0 ? void 0 : user.canSwitchProfile) {
            options.push({
                itemKey: 'profile-switcher',
                children: (_jsx(ItemWrapper, { children: _jsx(MenuProfileSwitcher, {}) })),
                onClick: onSwitchProfile,
            });
        }
        options = [
            ...options,
            {
                itemKey: 'my-profile',
                children: (_jsx(RouterLinkStyled, { to: getAbsolutePath(routes.MY_PROFILE), tabIndex: -1, children: _jsx(IconTextStyled, { IconComponent: _jsx(Icon, { size: 16, IconComponent: ProfileIcon }), text: t('pages.my_profile', { ns: 'common' }) }) })),
                onClick: navigateToProfile,
            },
            {
                itemKey: 'language',
                children: (_jsxs(OptionSettingsContainer, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: LanguageCommonIcon })), text: t('settings.language', { ns: 'common' }) }), _jsxs(ActiveSettingsContainer, { children: [_jsx(Body, { color: theme.palette.text.secondary, children: languages[currentLanguage] }), _jsx(Icon, { color: '#C1C8CD', size: 20, IconComponent: ArrowRightIcon })] })] })),
            },
            {
                itemKey: 'theme',
                children: (_jsxs(OptionSettingsContainer, { children: [_jsx(IconTextStyled, { IconComponent: _jsx(Icon, { size: 16, IconComponent: ThemeIcon }), text: t('settings.theme', { ns: 'common' }) }), _jsxs(ActiveSettingsContainer, { children: [_jsx(Body, { color: theme.palette.text.secondary, children: currentTheme === 'light' ?
                                        t('settings.themes.light', { ns: 'common' }) :
                                        t('settings.themes.dark', { ns: 'common' }) }), _jsx(Icon, { color: '#C1C8CD', size: 20, IconComponent: ArrowRightIcon })] })] })),
            },
            {
                itemKey: 'settings',
                children: (_jsx(RouterLinkStyled, { to: getAbsolutePath(routes.SETTINGS), tabIndex: -1, children: _jsx(IconTextStyled, { IconComponent: _jsx(Icon, { size: 16, IconComponent: SettingsIcon }), text: t('pages.settings', { ns: 'common' }) }) })),
                onClick: goToSettings,
            },
            {
                itemKey: 'shortcuts',
                children: (_jsx(ItemWrapper, { children: _jsx(IconTextStyled, { IconComponent: _jsx(Icon, { size: 16, IconComponent: ShortcutsIcon }), text: t('pages.shortcuts', { ns: 'common' }) }) })),
            },
            {
                itemKey: 'support',
                children: (_jsx(RouterLinkStyled, { to: getAbsolutePath(routes.SUPPORT), tabIndex: -1, children: _jsx(IconTextStyled, { IconComponent: _jsx(Icon, { size: 16, IconComponent: SupportIcon }), text: t('pages.support', { ns: 'common' }) }) })),
                onClick: goToSupport,
                addDivider: true,
            },
        ];
        if (user === null || user === void 0 ? void 0 : user.isSuperuser) {
            options.push({
                itemKey: 'dev-components-toggle',
                children: (_jsx(ItemWrapper, { children: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: AlertIcon })), text: `Dev Components: ${areDevComponentsEnabled ? 'Enabled' : 'Disabled'}` }) })),
                onClick: onDevComponentsToggleClick,
            });
            options.push({
                itemKey: 'dev-components',
                addDivider: true,
                children: (_jsx(ItemWrapper, { children: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: AlertIcon })), text: 'Dev Components Page' }) })),
                onClick: onDevComponentsClick,
            });
        }
        if (typeof user !== 'undefined') {
            if (!isProfileComplete && !user.traderProfile) {
                options.push({
                    itemKey: 'action-buttons',
                    children: (_jsxs(ButtonContainer, { children: [_jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: goToProfileEdit, fullWidth: true, children: t('actions.complete_profile', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'warning', size: 'medium', startIcon: CrownIcon, onClick: openCreateTraderForm, fullWidth: true, children: t('actions.create_trader', { ns: 'common' }) })] })),
                    disableHover: true,
                    disableActive: true,
                    addDivider: true,
                });
            }
            else if (!isProfileComplete) {
                options.push({
                    itemKey: 'complete-profile',
                    children: (_jsx(ContainerColumn, { children: _jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: goToProfileEdit, fullWidth: true, children: t('actions.complete_profile', { ns: 'common' }) }) })),
                    disableHover: true,
                    disableActive: true,
                    addDivider: true,
                });
            }
            else if (!user.traderProfile) {
                options.push({
                    itemKey: 'create-trader',
                    children: (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'tinted', color: 'warning', size: 'medium', startIcon: CrownIcon, onClick: openCreateTraderForm, fullWidth: true, children: t('actions.create_trader', { ns: 'common' }) }) })),
                    disableHover: true,
                    disableActive: true,
                    addDivider: true,
                });
            }
        }
        options.push({
            itemKey: 'logout',
            children: (_jsx(ItemWrapper, { children: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: LogoutIcon })), text: t('actions.log_out', { ns: 'common' }) }) })),
            onClick: onLogoutClick,
        });
        return options;
    }, [user, currentTheme, currentLanguage, languages, areDevComponentsEnabled]);
    return (_jsx(DropdownMenu, { ref: anchorEl, role: 'navigation', isOpen: isOpen, onClose: onClose, popperStyles: {
            zIndex: 1,
        }, paperStyles: {
            minWidth: 260,
        }, listStyles: {
            paddingTop: 2,
            paddingBottom: 2,
        }, nestedVariants: {
            language: languageOptions,
            theme: {
                header: (_jsx(IconTextStyled, { text: t('settings.theme', { ns: 'common' }) })),
                showBackButton: true,
                children: [
                    {
                        itemKey: 'light',
                        children: (_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: ThemeLightIcon })), text: t('settings.themes.light', { ns: 'common' }) })),
                        onClick: onThemeChange,
                        selected: currentTheme === 'light',
                    },
                    {
                        itemKey: 'dark',
                        children: (_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: ThemeDarkIcon })), text: t('settings.themes.dark', { ns: 'common' }) })),
                        onClick: onThemeChange,
                        selected: currentTheme === 'dark',
                    },
                ],
            },
        }, items: menuItems, disablePortal: false, zIndex: 1, transition: true, removePadding: true }));
};
const TopbarProfileMenu = forwardRef(TopbarProfileMenuComponent);
export default TopbarProfileMenu;
